body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#F5F5F5";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTypography-root {
  text-transform: none !important;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-ExtraBold";
  src: local("Poppins-ExtraBold"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Black";
  src: local("Poppins-Black"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}
/* cookies nbanner */
.cky-consent-container {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background:#00000040 !important;

}
.cky-consent-container .cky-consent-bar {
  border-color: #f4f4f4;
  background-color: #FFFFFF !important;
  width: 30% !important;
  margin: auto !important;
  margin-top: calc(50vh - 100px) !important;
}
@media screen and (max-width: 767px){
  .cky-consent-container .cky-consent-bar {
    width: 90% !important;
  }
}

.basket-items {
  padding-left: 0.5rem;
}
.basket-items--item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}
.basket-items--details {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 1rem;
}
.basket-items--details-price {
  text-wrap: nowrap;
}
.basket-vat-text{
  font-weight: 500;
  margin-bottom: 0;
}
.loading {
    font-size: 20px;
    animation: loadingProgress 1s linear infinite forwards;
    filter: invert(55%) sepia(95%) saturate(277%) hue-rotate(146deg) brightness(102%) contrast(94%);
    /* timing-function delay iteration-count direction fill-mode */
}

.loading-fill {
    animation: loadingFill 1s linear 0 infinite normal forwards;
}

@keyframes loadingFill {
    25% {
        fill: #8D88FF;
        width: 25%;
        left: 25%;
    }
    50% {
        fill: #8D88FF;
        width: 50%;
        left: 50%;
    }
    75% {
        fill: #8D88FF;
        width: 75%;
        left: 75%;
    }
    100% {
        fill: #8D88FF;
        left: 100%;
        width: 100%;
    }
}

@keyframes loadingProgress {
    from {
        left: 0%;
        fill: #8D88FF;
    }
    to {
        left: 100%;
        fill: #8D88FF;
    }
}
.Calendar {
    width: 44vw;
    max-height: 82vh;
    height: 100%;
    box-sizing: border-box;
    box-shadow: none;
}

.Calendar__sectionWrapper {
    height: 46vh;
    max-height: 50vh;
}

.Calendar__section.-shown {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    /* max-height: 78vh; */
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    /* bottom: 0.5em; */
    display: none;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    bottom: 0.5em;
}

.Calendar__day.-ltr {
    padding: 4vh;
    height: 100%;
    max-height: 100%
}

.Calendar__section.-hiddenNext {
    transform: translateX(100%);
}

.Calendar__section.-hiddenPrevious {
    opacity: 0.5;
    transform: translateX(-100%);
}

.Calendar__monthText {
    background-color: #4bc0e2;
}

.Calendar__day.-selected {
    height: 100%;
    max-height: 100%;
    padding: 0;
    margin-bottom: 3vh;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
    color: #fff;
    background-color: #4bc0e2;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    bottom: 1.8em;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background: #F7F6F4;
    border-radius: 1px;
}

.Calendar__weekDays {
    align-items: center;
    background: #F7F6F4 !important;
    display: flex;
    justify-content: space-between;
    height: 40px;
    color: black;
    font-weight: bold;
    border: 1px solid lightgray;
}